.hero {
    background-image: url(../../assets/banner_mosala_c.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    width: 93%;
    max-width: 1550px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0!important;
    margin: auto;
    border-radius: 50px;
    height: 85vh;
    overflow: hidden;
    position: relative;
}

.hero .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.actions {
    width: 355px;
    max-width: 70%;
    min-width: 200px;
    margin-top: 20px;
}

.hero-content div:first-child {
    /* flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: inherit;
    justify-content: center; */
}

.hero .actions .btn {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500!important;
    padding: 10px 0;
}

.hero .hero-content .title {
    font-size: 50px;
    font-weight: bold!important;
    text-align: right;
}

.hero .hero-content .devise {
    font-size: 23px;
    margin-top: 20px;
}

.hero-image {
    flex-grow: 1;
    flex-basis: 900px;
}

.help {
    background-color: #ffffff;
    padding: 70px 20px;
}

.help .inner-content {
    padding: 0;
}

.services {
    padding: 30px 0;
}

.service {
    position: relative;
    z-index: 2;
    padding-bottom: 0!important;
    background-color: #fff;
    padding: 70px 0!important;
}

.service::before {
    content: '';
    height: 25%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fbfbfb;
    background-image: linear-gradient(to top, #f5f5f5, rgb(255, 255, 255));
    z-index: 1;
}

.usualy-demand {
    padding: 0px 0px 100px;
    background-color: #f5f5f5;
}

.service .inner-content{
    padding: 0;
}

.services .card-container {
    display: flex;
    justify-content: center;
}

.home-service-card {
    transition: background 0.2s, scale .5s, box-shadow 0.2s;
    cursor: pointer;
}

.home-service-card:hover img {
    animation: rot 1.5s;
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.engagement{
    background-color: #868d92;
    background: #0F2027;  /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5686ff, #19428e, #011d55); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    ;
    position: relative;
    color: #fff;
}

.engagement .btn {
    font-size: 22px!important;
    font-weight: 500!important;
}

.devenir {
    position: relative;
    background-color: #fff;
    background-image: url(../../assets/techniciens_b.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.faq {
    background-color: #f5f5f5;
}

.scroll-indicator {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 70px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 25px;
}

.animated-span {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 30px;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    animation: scrollindicator 2.5s infinite;
    animation-fill-mode: forwards;
}

@keyframes scrollindicator {
    0% {
        top: 10px;
    }
    100% {
        top: 27px;
    }
}


@media (max-width: 800px) {
    .hero .hero-content .title {
        font-size: 48px;
    }
    .hero .hero-content{
        width: 100%;
        height: 63vh;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 100%;
    }
    .hero-image {
        max-width: 300px;
        flex-basis: auto;
        flex-grow: initial;
    }
    .hero-content div:first-child {
        align-items: center;
    }
}
@media (max-width: 600px) {
    .devenir,
    .hero {
        background-position: revert;
    }
    .devenir::before,
    .hero::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0;
        right: 0;
        background-color: #00000054;
    }
    .hero::before {
        height: calc(100vh - 30px);
    }
    .hero .hero-content {
        min-height: calc(100vh - 30px);
    }
    .hero .hero-content .title {
        font-size: 30px;
        text-align: center;
    }
    .hero .hero-content .devise {
        font-size: 17px;
        margin-top: 10px;
    }
    .hero .actions {
        margin-top: 10px;
    }
    .engagement .actions,
    .actions {
        width: 90%!important;
    }
    .engagement .btn,
    .hero .actions .btn {
        font-size: 17px!important;
        padding: 6px 0;
        margin: 0
    }
    .hero-image {
        max-width: 170px;
    }
    .help {
        background-color: #fbfbfb;
    }
}