.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form {
    background-color: #fff;
    min-height: fit-content;
    padding: 20px 22px;
    width: 400px;
    color: #444;
    box-shadow: 0px 0px 2px 0px #c5c5c5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form a:hover {
    text-decoration: underline;
}

.login-form > div {
    width: 100%;
}

.login .logo-container {
    display: block;
    width: 85px;
    height: 60px;
    margin-bottom: 20px;
}

.login-footer {
    margin-top: 20px;
    max-width: 400px;
    text-align: center;
}

@media (max-width: 600px) {
    .login-form{
        width: 100%;
    }
}