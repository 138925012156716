.profile {
    min-height: 100vh;
    width: 100%;
    background-color: #fff;
}

.dash-card {
    flex: 1;
    padding: 15px;
    box-shadow: 0px 0px 1px 0px #9c9c9c;
}

.dash-card p {
    line-height: 1;
}

.dash-card p:first-child {
    color: #2340c1d5;
    font-weight: 600!important;
}

.dash-card p:last-child {
    font-size: 17px!important;
    color: #2340c1d5;
    margin-top: 10px;
}

.profile .user-avatar { 
    width: 170px!important; 
    height: 170px!important;
    background-color: #f0f0f0!important;
    color: #444!important;
}

.user-description {
    flex-grow: 1;
    margin-left: 30px;
}

.profile .main-content > *:not(:first-child) {
    padding: 10px 0;
}

.profile .user-data {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
}

.profile .user-description .vertical-list {
    margin-bottom: 15px;
}

.join {
    width: 100%;
    box-shadow: 0px 0px 1px 0px #9c9c9c;   
    margin-top: 30px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.join img {
    width: 100%;
}

.join .btn {
    margin-top: 20px;
}

.profile .danger-zone {
    margin-top: 30px;
}

.profile form {
    max-width: 400px;
    margin: auto;
    box-shadow: 0px 0px 1px 0px #9c9c9c;
    padding: 20px;
}

.profile form > div:last-child {
    margin-top: 20px;
    display: flex;
}

.profile form > div:last-child > button:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 1000px) {
    .profile .user-data {
        flex-direction: column;
    }
    .user-description {
        margin-left: 0;
        margin-top: 30px;
    }
}


@media (max-width: 800px) {
    .profile .horizontal-list {
        flex-direction: column;
        align-items: flex-start;
    }
    .profile .horizontal-list > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .profile .horizontal-list > div {
        width: 100%;
    }
    .profile .user-avatar {
        width: 130px!important;
        height: 130px!important
    }
}
