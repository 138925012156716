.service-card {
    width: 220px;
    height: 340px;
    box-shadow: 0px 2px 13px 0px #33333326;
    cursor: pointer;
    transition: box-shadow .2s;
    border-radius: 5px;
    overflow: hidden;
}

.service-card:hover {
    box-shadow: 0px 2px 13px 0px #5757570e;
}

.service-card:not(:first-child) {
    margin-left: 15px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 150px;
}

.service-card .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.service-card .name {
    font-size: 19px;
    margin: 0 0 10px 0;
}