.tech-container {
    background-color: #fff;
    padding: 20px;
}

.tech-container header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.tech-container header img {
    width: 85px;
    height: 60px;
}

.step {
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #444;
}

.step1, .step2 {
    max-width: 500px;
    min-height: 50vh;
}


/* Step */
.step form {
    max-width: 100%;
    margin: auto;
}

.step1 {
    box-shadow: 0px 0px 1px 0px rgb(17 17 17 / 18%), 0px 0px 1px 0px rgb(17 17 17 / 12%), 0px 0px 1px 0px rgb(17 17 17 / 12%), 0px 0px 1px 0px rgb(17 17 17 / 12%);
    padding: 40px 30px;
}

.step-title {
    font-size: 18px!important;
    margin-bottom: 30px!important;
    display: flex;
}

.step-title span:first-child {
    color: #0020b1b8;
    width: -moz-fit-content;
    width: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding-bottom: 12px;
    border-bottom: 3px solid #0020b1a3;
    margin-right: 10px;
}

.step-title span:last-child {
    flex-grow: 1;
}

.account-card {
    padding: 10px 15px;
    border: 1px solid #7070708a;
    margin: 25px 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.account-card svg {
    margin-right: 10px;
}

.step1 > div:last-child {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Step 2 */

.tech-banner {
    background-color: #0230ff;
    background-image: url(../../assets/tech-banner.svg);
    background-position: center;
    background-size: cover;
    color: #fff;
}

.inner-tech-banner {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-items: self-end;
    padding: 20px 40px;
    margin: auto;
}

.tech-big-title{
    font-size: 37px!important;
    margin-bottom: 10px!important;
}

.hat {
    max-width: 155px;
}

.step2 {
    padding: 20px 40px;
    box-shadow: 0px 0px 1px 0px rgb(17 17 17 / 18%), 0px 0px 1px 0px rgb(17 17 17 / 12%), 0px 0px 1px 0px rgb(17 17 17 / 12%), 0px 0px 1px 0px rgb(17 17 17 / 12%);
    width: 500px;
}

.form-step-title {
    margin-bottom: 10px!important;
    font-size: 16px;
}

.step2 .radio {
    display: flex;
    align-items: center;
}

.step2 .check-container {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 5px
}

.formations {
    display: grid;
    grid-template-columns: auto;
}

.formations label p {
    text-transform: initial!important;
}

.step2 .form-actions {
    margin-top: 15px;
    justify-content: flex-start;
}

.step2 .form-actions .btn:not(:first-child) {
    margin-left: 15px;
}

.step2 .form-group {
    max-width: 300px;
}

.step2 .form-group > *:last-child {
    margin-top: 15px;
}

@media (max-width: 750px) {
    .inner-tech-banner {
        grid-template-columns: 1fr;
        gap: 20px;
        justify-items: center;
        text-align: center;
    }
    .hat{
        grid-row-start: 1;
    }
}
@media (max-width: 600px) {
    .tech-container {
        padding: 12px;
    }
    .inner-tech-banner {
        gap: 10px;
        padding: 20px 20px;
    }
    .tech-big-title{
        font-size: 25px!important;
    }
    .tech-container .small-title {
        font-size: 12px!important;
    }
    .hat{
        max-width: 100px;
    }
    .tech-account .step{
        padding: 20px 20px;
    }
    .step1, .step2 {
        padding: 25px 15px;
        width: 100%;
    }
    .step1 > div:last-child{
        flex-direction: column;
        align-items: flex-start;
        max-width: 200px;
    }
    .step1 > div:last-child > .btn:not(:first-child){
        margin-top: 10px;
    }
}
