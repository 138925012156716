.dash-container {
    background-color: #fff;
}

.dash-content {
    height: 100%;
    width: 100%;
    color: #484848;
}

.dash-content .main-content {
    max-width: 100%;
    padding: 0 20px;
}

.main-content > .header-container {
    height: 70px;
}

.main-content header {
    background: #fff;
    border-bottom: 1px solid #f3f3f3;
}

.dash-content > article{
    padding: 0 20px;
}

.dash-content header {
    padding: 20px;
}

.dash-bg-title {
    color: #252525e5;
    font-size: 27px!important;
    letter-spacing: -1px!important;
}

.dash-sm-title {
    font-size: 15px!important;
    margin-bottom: 10px!important;
}

.danger-zone {
    border-top: 1px solid #eaeaea;
    padding: 30px 0!important;
}

.danger-zone .dash-sm-title {
    margin-bottom: 30px!important;
}

@media (max-width: 700px) {
    .dash-content header {
        padding: 30px 15px;
    }
    .dash-container .main-content {
        padding: 0 15px;
    }
}
