.trouver-artisan {
    color: #fff;
    padding: 0;
    min-height: calc(100vh - 70px);
    display: flex;
}

.trouver-artisan .container {
    display: flex;
    justify-content: flex-end;
}

.trouver-artisan .content-container {
    flex: 2;
}

.banner {
    /* background: url(../../assets/electricityBanner.jpg); */
    background-size: cover;
    background-position: right;
    width: 100%;
    flex: 1;
    display: flex
}

.inner-banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    text-align: end;
    max-width: 500px;
    align-items: flex-end;
    max-height: calc(100vh - 80px);
}

.banner .content-title {
    font-size: 37px;
    font-weight: 500;
    margin-bottom: 10px!important;
}

.banner .content-detail {
    font-size: 17px;
    margin-top: 3px;
}

.checkbox-label {
    font-size: 15px!important;
}

.artisan-container {
    margin: auto;
    transform: translateY(-55px);
    padding: 30px;
    background-color: #fff;
    min-width: fit-content;
    max-width: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;
    box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 1px 12px -1px rgb(0 0 0 / 12%);
    border-radius: 5px;
}

.artisan-list {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    z-index: 15;
}

.question {
    font-size: 23px!important;
    text-align: center;
    margin: 0 0 20px 0!important;
}

.reservation-form{
    width: 100%;
    height: 100%;
    padding: 30px;
}

.content-body {
    height: calc(100% - 32px);
    margin: 0;
}

.form-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-content {
    flex: 1;
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-content-title {
    margin-bottom: 5px!important;
}

.content-title {
    margin-bottom: 20px!important;
}

@media (max-width: 1050px) {
    .trouver-artisan {
        flex-direction: column;
    }
    .inner-banner{
        align-items: center;
        padding: 30px;
        text-align: center;
        max-width: 100%;
    }
    .banner {
        height: 170px;
    }
}
@media (max-width: 800px) {
    .banner .content-title{
        font-size: 28px;
        margin-bottom: 5px!important;
    }
    .banner .content-detail {
        font-size: 15px;
    }
}
@media (max-width: 600px) {
    .banner{
        text-align: center;
    }
    .inner-banner{
        align-items: center;
    }
    .reservation-form {
        padding: 30px 10px;
    }
    .content-title {
        margin-bottom: 10px!important;
    }
}