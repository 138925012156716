.login {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fbfbfb
}

.login .logo-container {
    margin-bottom: 20px!important;
}